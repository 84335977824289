<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio lead</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="lead_v2">
                    <div class="customer">
                        {{ lead.nominative }}
                    </div>
                    <div v-if="lead.city" class="city">
                        {{ lead.city }}
                    </div>
                    <div class="valore">
                        <span class="badge_value"> € {{ lead.value }} </span>
                    </div>

                    <div class="field">
                        <div class="title">Titolo</div>
                        <div class="value">
                            {{ lead.title }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Email</div>
                        <div class="value">
                            <span v-if="lead.email">
                                <a :href="`mailto:${lead.email}`" class="mobile_link">{{ setShortEmail(lead.email) }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Cellulare</div>
                        <div class="value">
                            <span v-if="lead.mobile">
                                <a :href="`tel:${lead.mobile}`" class="mobile_link">{{ lead.mobile }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Telefono</div>
                        <div class="value">
                            <span v-if="lead.phone">
                                <a :href="`tel:${lead.phone}`" class="mobile_link">{{ lead.phone }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field" v-if="lead.recall_date">
                        <div class="title">Richiamare il</div>
                        <div class="value">
                            <span class="badge" :class="isExpired(lead)">
                                {{ dateFormat(lead.recall_date) }}
                            </span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Probabilità chiusura</div>
                        <div class="value">
                            {{ lead.rating ? `${lead.rating}/5` : "-" }}
                        </div>
                    </div>
                </div>

                <!--         <div class="lead" style="display:none">
          <div class="field">
            <div class="title">Titolo</div>
            <div class="value">
              {{ lead.title }}
            </div>
          </div>
          <div class="field">
            <div class="title">Cliente</div>
            <div class="value">
              {{ lead.nominative }}
            </div>
          </div>
          <div class="field">
            <div class="title">Città</div>
            <div class="value">
              {{ lead.city ? lead.city : "-" }}
            </div>
          </div>
          <div class="field">
            <div class="title">Email</div>
            <div class="value">
              {{ lead.email ? lead.email : "-" }}
            </div>
          </div>
          <div class="field">
            <div class="title">Cellulare</div>
            <div class="value">
              <span v-if="lead.mobile">
                <a :href="`tel:${lead.mobile}`" class="mobile_link">{{ lead.mobile }}</a>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="field">
            <div class="title">Telefono</div>
            <div class="value">
              <span v-if="lead.phone">
                <a :href="`tel:${lead.phone}`" class="mobile_link">{{ lead.phone }}</a>
              </span>
              <span v-else>-</span>
            </div>
          </div>
          <div class="field">
            <div class="title">Data recall</div>
            <div class="value">
              <span class="badge" :class="isExpired(lead)">
                {{ dateFormat(lead.recall_date) }}
              </span>
            </div>
          </div>
          <div class="field">
            <div class="title">Probabilità chiusura</div>
            <div class="value">
              {{ lead.rating ? `${lead.rating}/5` : "-" }}
            </div>
          </div>
          <div class="field">
            <div class="title">Valore</div>
            <div class="value">
              <span class="badge badge_success"> € {{ lead.value }} </span>
            </div>
          </div>
        </div> -->

                <div class="messages_container">
                    <div class="title">
                        Messaggi
                    </div>
                    <div v-if="loading" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else>
                        <div v-if="leadMessages.length != 0" class="messages">
                            <div
                                v-for="message in leadMessages"
                                :key="message.lead_messages_id"
                                class="single_message"
                                :class="isWrittenByMe(message.leads_messages_user_id)"
                            >
                                <div class="text">{{ message.leads_messages_text }}</div>
                                <div class="date">
                                    {{ dateFormatWithMinutes(message.leads_messages_creation_date) }}
                                </div>
                            </div>
                        </div>
                        <div v-else class="no_messages">
                            Nessun messaggio da visualizzare
                        </div>
                        <div class="input_container">
                            <ion-textarea type="text" rows="2" v-model="inputMessage" placeholder="Messaggio" class="custom_input"> </ion-textarea>
                            <div @click="sendMessage" class="send_message">
                                <ion-icon :icon="arrowForwardCircle"></ion-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonTextarea,
    IonSpinner,
    modalController,
} from "@ionic/vue";
import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";

import moment from "moment";

import { dateFormat, dateFormatWithMinutes } from "../services/utils";
import { openToast } from "../services/toast";

import apiLeads from "../services/leads";

export default defineComponent({
    name: "LeadDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonTextarea,
        IonSpinner,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params
        const router = useRouter();
        const loggeUserId = JSON.parse(localStorage.getItem("unserInfoEntasys")).dipendenti_user_id;

        const loading = ref(false);

        const lead = ref([]);
        //console.log(props.data);
        lead.value = { ...props.data };
        //console.log(lead.value);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Set corrent bg class for recall date
         */
        const isExpired = computed(() => {
            return (lead) => {
                let className = "";
                const leadDate = moment(lead.recall_date);
                const todayDate = moment();

                if (leadDate.isBefore(todayDate)) {
                    className = "badge_danger";
                }
                return className;
            };
        });

        /**
         * ! Limit lead email to 35 charactes
         */
        function setShortEmail(email) {
            if (!email) {
                return "-";
            } else {
                const trimmedString = email.length > 30 ? email.substring(0, 27) + "..." : email;
                return trimmedString;
            }
        }

        /**
         * ! Get lead messages
         */
        const leadMessages = ref([]);

        async function getLeadMessages() {
            loading.value = true;
            try {
                const res = await apiLeads.loadMessages(lead.value.id);
                leadMessages.value = res.data;
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei messaggi", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * ! Set classes based on who written the message
         */
        const isWrittenByMe = computed(() => {
            return (userId) => {
                let className = "";

                if (userId === loggeUserId) {
                    className = "my_message";
                }
                return className;
            };
        });

        const inputMessage = ref("");

        async function sendMessage() {
            if (inputMessage.value) {
                const data = new FormData();
                data.append("leads_messages_customer_id", lead.value.id); //lead id
                data.append("leads_messages_user_id", lead.value.created_by); //logged user id
                data.append("leads_messages_text", inputMessage.value);

                apiLeads
                    .createMessage(data)
                    .then((response) => {
                        if (response.status === 0) {
                            const createdMessage = response.data[0];
                            //inserimento in array messaggi
                            leadMessages.value.push(createdMessage);
                            inputMessage.value = "";
                        } else {
                            openToast("Errore durante l'invio del messaggio", "toast_danger");
                        }
                    })
                    .catch((error) => {
                        //console.error(error);
                        openToast("Errore durante l'invio del messaggio", "toast_danger");
                    });
            }
        }

        onMounted(() => {
            getLeadMessages();
        });

        return {
            loading,
            isExpired,
            dateFormat,
            dateFormatWithMinutes,
            lead,
            arrowBackOutline,
            closeModal,
            getLeadMessages,
            leadMessages,
            isWrittenByMe,
            inputMessage,
            arrowForwardCircle,
            sendMessage,
            setShortEmail,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

/* lead v2*/
.lead_v2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.lead_v2 .customer {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.lead_v2 .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.lead_v2 .valore {
    margin-bottom: 16px;
}
.lead_v2 .valore .badge_value {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.lead_v2 .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.lead_v2 .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: none;
}
.lead_v2 .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

/** lead standard */
.lead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.lead .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.lead .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: none;
}
.lead .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.value .badge_success {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.value .badge_warning {
    background-color: #f97316;
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.value .badge_danger {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}
ion-button {
    --color: #ffffff;
}

/* Messages */
.messages_container {
    margin-top: 20px;
}
.messages_container .title {
    font-size: 16px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.messages_container .no_messages {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}
.messages_container .messages {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: scroll;
}
.messages_container .single_message {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.messages_container .single_message .text {
    font-size: 13px;
    margin-bottom: 4px;
}
.messages_container .single_message .date {
    align-self: end;
    font-size: 10px;
    color: #64748b;
}
.messages_container .single_message.my_message {
    align-self: end;
    background-color: #086fa3;
    color: #ffffff;
}
.messages_container .single_message.my_message .date {
    color: #ffffff;
}

.input_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
.send_message {
    width: 10%;
    display: flex;
    justify-content: flex-end;
}
.send_message ion-icon {
    width: 32px;
    height: 32px;
    color: #086fa3;
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}
</style>
